<template>
  <div>
    <div>
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="名称" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space style="margin-top: 4px">
                <Button type="primary" @click="query">查询</Button>
                <Button @click="reset">重置</Button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <Button
                type="primary"
                @click="$router.push('/drainage/emergancy/riskMember/add')"
                >新建</Button
              >
            </div>
          </a-col>
        </a-row>
      </a-form-model>
      <Padding />

      <a-table
        :dataSource="list"
        @change="onChange"
        :loading="loading"
        rowKey="id"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column key="name" title="姓名" data-index="name" />
        <a-table-column key="mobile" title="手机号" data-index="mobile" />

        <a-table-column
          key="isCharger"
          title="是否负责人"
          data-index="isCharger"
        >
          <template slot-scope="text">
            <span>{{ text ? "是" : "否" }}</span>
          </template>
        </a-table-column>
        <a-table-column
          key="isNotify"
          title="是否接收短信通知"
          data-index="isNotify"
        >
          <template slot-scope="text">
            <span>{{ text ? "是" : "否" }}</span>
          </template>
        </a-table-column>
        <a-table-column
          key="photo"
          title="照片"
          width="200px"
          :ellipsis="true"
          data-index="photo"
        />
        <a-table-column key="duty" title="角色" data-index="duty" />

        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="toEdit(text)">编辑</a>
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/risk/riskMember";
export default {
  components: {},
  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({ id: text.id }).then(() => {
            that.getList();
          });
        },
      });
    },
    toEdit(item) {
      this.$router.push({
        path: "/drainage/emergancy/riskMember/edit",
        query: { detail: item },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>
